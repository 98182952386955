.static-view {
	margin-top: var(--page-margin-top);



	&.informationen-us-v1 {
		.anchor {
			display: block;
			height: 150px;
			margin-top: -150px;
			visibility: hidden;
		}
	}

	&.anreise-v1 {
		margin-top: 0;

		@media (max-width: 767px) {
			margin-top: 60px;
		}

		.inner-banner {
			height: auto;
			background: none;

			img {
				width: 100%;
				height: auto;
			}
		}


		.container {
			padding-top: 2.5vw;
			background-color: hsla(0, 0%, 100%, .9) !important;
			border-radius: 10px;
			min-height: 500px;
			margin-top: -25vw !important;
			position: relative;
			padding-left: 30px !important;
			padding-right: 30px !important;
			margin-bottom: 70px !important;
			z-index: 1;
			width: calc(100% - 30px);

			@media (max-width: 991px) {
				width: calc(100% - 30px);
				padding-left: 15px !important;
				padding-right: 15px !important;
			}
		}
	}

	&.essentrinken-v1 {

		margin-top: 0;

		@media (max-width: 767px) {
			margin-top: 60px;
		}



		.inner-banner {
			height: auto;
			background: none;

			img {
				width: 100%;
				height: auto;
			}
		}

		.holder {
			padding-top: 2.5vw;
			background-color: hsla(0, 0%, 100%, .9) !important;
			border-radius: 10px;
			min-height: 500px;
			margin-top: -25vw !important;
			position: relative;
			padding-left: 30px !important;
			padding-right: 30px !important;
			margin-bottom: 70px !important;
			width: calc(100% - 30px);

			@media (max-width: 991px) {
				width: calc(100% - 30px);
				padding-left: 15px !important;
				padding-right: 15px !important;
			}
		}

		.blog-list-v1 {
			h2 {
				color: #333;
				margin: 0 0 20px 0 !important;
				font-style: italic;
			}

			.ce_text {
				margin-top: 2rem;
				padding-top: 3rem;
				position: relative;
				border-top: 1px solid #dedede;
				text-align: left;
			}
		}
	}

	&.urlaubmithund {
		margin-top: 0;

		@media (max-width: 767px) {
			margin-top: 60px;
		}

		.inner-banner {
			height: auto;
			background: none;

			img {
				width: 100%;
				height: auto;
			}
		}


		.vacation-content-box {
			text-align: left;
			margin-top: 2rem;
			padding-top: 3rem;
			border-top: 1px solid #dedede;
		}

		.holder {
			padding-top: 2.5vw;
			background-color: hsla(0, 0%, 100%, .9) !important;
			border-radius: 10px;
			min-height: 500px;
			margin-top: -25vw !important;
			position: relative;
			padding-left: 30px !important;
			padding-right: 30px !important;
			margin-bottom: 70px !important;

			@media (max-width: 991px) {
				width: calc(100% - 30px);
				padding-left: 15px !important;
				padding-right: 15px !important;
			}
		}

		.blog-list-v1 {
			h2 {
				color: #333;
				margin: 0 0 20px 0 !important;
				font-style: italic;
			}

			.ce_text {
				margin-top: 2rem;
				padding-top: 3rem;
				position: relative;
				border-top: 1px solid #dedede;
				text-align: left;
			}
		}

	}

	.Galerie-section {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		height: auto !important;

		.jg-caption {
			display: none !important;
		}

		.imagegallery {
			padding: 0 15px !important;
			width: 33.33% !important;
			height: 300px !important;
			margin-bottom: 30px !important;


			@media (max-width: 1199px) {
				height: 180px !important;
			}

			@media (max-width: 991px) {
				height: 150px !important;
			}

			@media (max-width: 767px) {
				width: 100% !important;
				height: 250px !important;
			}

			@media (max-width: 500px) {
				height: 180px !important;
			}

			img {
				width: 100% !important;
				margin: 0 !important;
				height: 100% !important;
				object-fit: cover;
			}
		}
	}
}

#cboxCurrent,
#cboxTitle {
	top: -25px;
	font-size: 15px;
}