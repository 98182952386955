.unit-information-box-v1,
.facility-information-box-v1 {
	padding: 15px 15px;
	background-color: var(--widget-header-bg-color);
	font-size: var(--font-size-md);

	.map {

		.sidebar-map {
			width: 100%;
			height: 300px;
			position: relative;
		}
	}

	.location {

		.fa {
			margin-right: 8px;
			color: #a7a7a7;
		}
	}

	.facts {

		li {
			margin-bottom: 5px;

			.fa {
				width: 30px;
				color: #a7a7a7;
			}

			.fa-stack {
				margin-left: -10px;
			}

		}
	}

	.stars {
		color: var(--color-stars);
	}

	h4 {
		font-size: 22px;
	}

	a {
		&:hover {
			text-decoration: none;
		}

		h4 {
			margin-top: 10px;
			font-size: 18px;
		}

		img {
			max-width: 100%;
		}
	}

}