.search-offer-info-v1 {

	padding: 15px;
	background-color: #FFF2CD;
	margin-bottom: 1.5rem;
	border-radius: 10px;

	.offer-legende {
		font-weight: normal;
	}

	.offer-value {
		font-weight: bold;
	}

	.nowrap {
		white-space: nowrap;
	}

}