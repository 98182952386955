.hero-image-v1 {
	height: 100vh;
	min-height: 480px;
	overflow: hidden;
	background-image: url(RESOURCE/img/vide-banner-4.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	padding-top: 125px;

	@media (max-width:1200px) {
		min-height: inherit;
		height: auto;
		width: 100%;
		padding-top: 65px;
	}


	.video {
		height: 100vh;
		min-height: 680px;
		background-size: cover;
		background-position: center;

		@media (max-width:992px) {
			width: 100%;
			min-height: inherit;
			height: auto;
		}
	}


	/*	@media (max-width: 992px) {
		height: 100vh;
		min-height: 650px;
	}*/


	.hero-header {
		display: none;
		position: absolute;
		top: 64vh;
		width: 100%;
		z-index: 3;
		padding: 0 5px;

		@media (max-width: 992px) {
			top: 34vh;
		}



		@media (max-height: 600px) {
			top: 200px;

		}

		.hero-text {
			text-align: center;
			width: 100%;

			.hero-headline {
				width: 100%;
			}

			h1 {
				font-family: var(--hero-header-font-family);
				font-size: 4vw;
				color: #fff;
				text-shadow: 0 0.1rem 1rem rgba(0, 0, 0, .7);

				font-weight: 400;
				margin-bottom: 0px;

				@media (min-width: 1600px) {
					font-size: 55px;
				}

				@media (max-width: 1140px) {
					font-size: 48px;
				}

				@media (max-width: 992px) {
					font-size: 2.8rem;
					line-height: 3rem;
				}

				@media (max-width: 576px) {
					font-size: 2.5rem;
					line-height: 2.8rem;
				}

				@media (max-height: 600px) {
					font-size: 2.5rem;
					line-height: 2.5rem;

				}

			}

			.hero-subtitle {
				font-family: var(--hero-header-sub-title-font-family);
				font-size: 2.3rem;
				color: #fff;
				text-shadow: 0 0.1rem 1rem rgba(0, 0, 0, .7);

				@media (min-width: 1600px) {
					font-size: 2.3rem;
				}

				@media (max-width: 1140px) {
					font-size: 2.0rem;
				}

				@media (max-width: 992px) {
					font-size: 1.7rem;
				}

				@media (max-width: 575px) {
					font-size: 1.2rem;
				}
			}
		}

	}
}