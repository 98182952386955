.blogentry-v1 {
	padding-top: var(--page-margin-top);

	.img-thumbnail {
		height: 100% !important;
	}

	.date {
		padding-top: 10px;
		font-size: var(--font-size-md);
	}

	.content-block {
		box-shadow: 0 0.1rem 1rem rgb(0 0 0 / .1);
	}

	.img-col {
		position: relative;

		.percent {
			position: absolute;
			left: 0px;
			font-size: 20px;
			font-weight: bold;
			color: var(--color-white);
			background-color: var(--color-red);
			padding: 10px;
		}
	}

	.offer-title {
		padding: 10px 0px;
		font-size: 1.2rem;
		font-weight: bold;
	}

	.blogentry-header {
		.right {
			@media (max-width: 991px) {
				margin: 30px 0 0 0;

			}

			.tag-box {
				display: flex;
				flex-wrap: wrap;
				justify-content: end;

				@media (max-width: 991px) {
					justify-content: unset;
				}

				a {
					margin: 3px;
				}
			}
		}
	}

}